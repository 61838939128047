export default {
    emailLists: {
        list: '/business/campaign-email-lists',
        detail: (id) => `/business/campaign-email-lists/${id}/`,
        create: '/business/campaign-email-lists/',
        update: (id) => `/business/campaign-email-lists/${id}/`,
        delete: (id) => `/business/campaign-email-lists/${id}`,
        listAll: '/basic/email-lists/',
        createApi: '/basic/email-lists/',
        subscribers: {
            list: (id, mail_type) => `/basic/email-lists/${id}/subscribers/${mail_type}/`,
            create: (id, mail_type) => `/basic/email-lists/${id}/subscribers/api/`,
        }
    },
    automations: {
        list: '/business/email-automations',
        detail: (id) => `/business/email-automations/${id}/`,
        create: '/business/email-automations/',
        update: (id) => `/business/email-automations/${id}/`,
        delete: (id) => `/business/email-automations/${id}`,
    },
    automationSteps: {
        list: '/business/email-automation-steps',
        detail: (id) => `/business/email-automation-steps/${id}/`,
        create: '/business/email-automation-steps/',
        update: (id) => `/business/email-automation-steps/${id}/`,
        delete: (id) => `/business/email-automation-steps/${id}`,
    },
    automationLogs: {
        list: '/business/email-automation-logs',
        detail: (id) => `/business/email-automation-logs/${id}/`,
        create: '/business/email-automation-logs/',
        update: (id) => `/business/email-automation-logs/${id}/`,
        delete: (id) => `/business/email-automation-logs/${id}`,
    },
    forms: {
        list: '/business/forms',
        detail: (id) => `/business/forms/${id}/`,
        create: '/business/forms/',
        update: (id) => `/business/forms/${id}/`,
        delete: (id) => `/business/forms/${id}`,
        submissions: {
            list: (id) => `/business/form/${(id)}/submissions`,
            delete: (id,submission_id) => `/business/form/${id}/submission/${submission_id}/delete`,
        }
    },
    public: {
        forms: {
            list: '/public-forms',
            detail: (id) => `/public-forms/${id}/`,
        },
    }
};

export const addParamsToUrl = (url, params) => {
    let newUrl = url;
    if (params) {
        newUrl += '?';
        Object.keys(params).forEach((key) => {
            newUrl += `${key}=${params[key]}&`;
        });
    }
    return newUrl;
}