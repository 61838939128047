import defaultPages from "../LeadSlide/Pages/DefaultPages";
import axios from "axios";
import defaultEmails from "../LeadSlide/Emails/DefaultEmails"
import urls from "@/helpers/urls";
import apiRequest from "@/helpers/apiHelper";

const state = {
    email: {
        name: 'loading'
    },
    isEmailTemplate: true,
    loading: false,
    templates: defaultEmails,
    emailLists: [],
    subscribers: [],
}

const actions = {
    saveEmail: (store, payload) => {

        store.commit('setEmailLoading', true);
        if(store.state.isEmailTemplate)
        {
            axios.post('/business/emails/', payload).then(function(res){
                store.commit('setEmail', res.data);
                store.commit('setIsEmailTemplate', false);
            }).catch(function(err){
                console.log('error saving email')
            }).finally(function() {
                store.commit('setEmailLoading', false);
            });

        } else  {
            axios.patch('/business/emails/'+payload.id+'/', payload).then(function(res){
                store.commit('setEmail', res.data);
            }).catch(function(err){
                console.log('error patching email')
            }).finally(function() {
                store.commit('setEmailLoading', false);
            })
        }
    },
    loadEmail: (store, payload) => {
        store.commit('setEmailLoading', true);
        store.commit('setIsEmailTemplate', payload.isTemplate);
        if(payload.isTemplate)
        {
            store.commit(
                'setEmail',
                defaultEmails.find(o => o.id === parseInt(payload.id))
            );
            store.commit('setEmailLoading', false);
        } else {
            // user page
            axios.get('/business/emails/'+ payload.id + '/').then(function(res) {
                console.log(res);
                store.commit('setEmail', res.data);
            }).catch(function(err){
                console.log('error loading email')
            }).finally(function() {
                store.commit('setEmailLoading', false);
            });
        }
    },
    async loadEmailLists({commit}) {
        commit('setLoading', true);
        commit('setSubscribers', []);
        const response = await apiRequest('GET', urls.emailLists.listAll);
        if(response.success) {
            commit('setEmailLists', response.data);
        }
        commit('setLoading', false);
        return response;
    },
    async loadSubscribers({commit}, payload) {
        commit('setLoading', true);
        const response = await apiRequest('GET', urls.emailLists.subscribers.list(payload.publicId, payload.subscribeType));
        if(response.success) {
            commit('setSubscribers', response.data);
        }
        commit('setLoading', false);
        return response;
    },
    async addEmailList({commit}, payload) {
        commit('setLoading', true);
        const response = await apiRequest('POST', urls.emailLists.createApi, payload);
        if(response.success) {
            commit('addAdditionalEmailList', response.data);
        }
        commit('setLoading', false);
        return response;
    },
    async addSubscriber({commit}, payload) {
        commit('setLoading', true);
        const response = await apiRequest('POST', urls.emailLists.subscribers.create(payload.publicId, payload.subscribeType), payload);
        if(response.success) {
            commit('addSubscriber', response.data);
        }
        commit('setLoading', false);
        return response;
    }
}

const getters = {
    emailTemplates: state => {
        return state.templates
    },
    email: state => {
        return state.email
    },
    isEmailTemplate: state => {
        return state.isEmailTemplate;
    },
    isEmailLoading: state => {
        return state.loading
    },
    defaultEmail: (state, id) => {
        return state.templates.find(o => o.id === id)
    },
    emailLists: state => {
        return state.emailLists
    },
    subscribers: state => {
        return state.subscribers
    },
}

const mutations = {
    setEmailLists: (state, payload) => {
        state.emailLists = payload;
    },
    addAdditionalEmailList: (state, payload) => {
        state.emailLists.push(payload);
    },
    setSubscribers: (state, payload) => {
        state.subscribers = payload;
    },
    addSubscriber: (state, payload) => {
        state.subscribers.push(payload);
    },
    setEmail: (state, payload) => {
        state.email = payload;
    },
    setLoading: (state, bool) => {
        state.loading = bool;
    },
    setIsEmailTemplate: (state, bool) => {
        state.isEmailTemplate = bool;
    },
    setEmailLoading: (state, bool) => {
        state.loading = bool;
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}